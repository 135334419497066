import { Injectable } from '@angular/core';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { IGetQuery, ISearchResponse2, ITableName, InputType } from '@Shared/models/index.model';
import { ApiService } from './api.service';
import { IFieldNameKVP, ReportFieldType } from '@Reusables/reusable-pages/Report/report-extras/report.model';
import { uniqBy } from 'lodash-es';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class TableRelationsService {
  readonly baseURL = environment.apiBaseUrl + '/rest/tables/relation/';
  readonly reportFieldTypeToInputTypeMap: { [k in ReportFieldType]: InputType } = {
    BOOLEAN: 'checkbox',
    TEXT: 'text',
    DATE: 'date',
    NUMBER: 'number',
  };
  constructor(
    private apiService: ApiService,
    public uS: UtilityService,
  ) {}

  getAllTableRelations = () => {
    return this.apiService.getWithLocalCache<ITableRelation[]>(`${this.baseURL}all`);
  };
  getAllTableGroups = () => {
    return this.apiService.getWithLocalCache<string[]>(`${this.baseURL}table-group/all`);
  };
  getAllTableFields = () => {
    return this.apiService.getWithLocalCache<ITableRelation[]>(`${this.baseURL}all`).pipe(
      map((r) => {
        const relations = uniqBy(r, 'primaryKey');
        const fieldNames = relations.map((x) => x.primaryKey);
        return fieldNames;
      }),
    );
  };
  getTablesByTableGroup = (tableGroup: string) => {
    return this.apiService
      .getWithLocalCache<ITableName[]>(`${this.baseURL}table/${tableGroup}`)
      .pipe(
        map((r) =>
          r
            .sort3('primaryTable')
            .map<ITableName>((x) => ({ _label: x.primaryTable?.split('_').join(' '), ...x })),
        ),
      );
  };
  getFieldNames = (tableName: string, schema: string = 'CRM') => {
    return this.apiService
      .getWithLocalCache<
        IFieldNameKVP[]
      >(environment.apiBaseUrl + `/rest/reports/${tableName}/${schema}/all-column`)
      .pipe(
        map((x) =>
          x.sort2('columnName', true).map<IFieldNameKVP>((r) => ({
            ...r,
            _inputType: this.reportFieldTypeToInputTypeMap[r.dataType] || 'text',
            _formattedName: this.uS.fieldToLabelMap(r?.columnName?.toLowerCase().replaceAll('_', ' ')),
          })),
        ),
      );
  };
  getFieldNamesAndSchema = (primaryTable: string) => {
    return this.searchTables({ primaryTable }).pipe(
      switchMap((res) => this.getFieldNames(primaryTable, res.content[0]?.schema)),
    );
  };
  getAllTables = () => {
    return this.apiService.getFromMemory<string[]>(`${this.baseURL}table/all`);
  };
  searchTables = (query: IGetQuery<{ primaryTable: string; schema: string; tableGroup: string }>) => {
    return this.apiService.get<ISearchResponse2<ITableRelation>>(`${this.baseURL}search`, query);
  };
  searchTablesByName = (primaryTable: string) => {
    return this.searchTables({ primaryTable }).pipe(map((r) => uniqBy(r.content, 'primaryTable')));
  };
  updateTable = (query: ITableRelation, id: number) => {
    return this.apiService.put<ITableRelation>(`${this.baseURL}${id}`, query);
  };
}

export interface ITableRelation {
  primaryTable: string;
  _primaryTableUpper?: string;
  secondaryTable: null | string;
  connector1: null | string;
  connector2: null | string;
  tableGroup: string;
  processConnector: null | string;
  schema: string;
  primaryKey?: string;
  array?: boolean;
  warehouse: null;
  id: number;
  description: null | string;
}
